import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../../store/cart';
import { formatCurrency } from '../../utils/price';

import DocumentIcon from '../../assets/icons/pdf2.svg';
import WordDocumentIcon from '../../assets/icons/word-icon.png';
import PowerPointIcon from '../../assets/icons/pptx-icon-no-bg.png';
import LockIcon from '../../assets/icons/lock.svg';
import LockSubIcon from '../../assets/icons/sub-lock.svg';
import UnlockIcon from '../../assets/icons/open-lock.svg';
import UnlockSubIcon from '../../assets/icons/open-sub-lock.svg';
import NoDataImage from '../../assets/images/no-data.svg';
import YoutubeLogo from '../../assets/images/youtube_logo.png';

const isBrowser = typeof window !== 'undefined';

const DocumentsList = ({ documents, showNoData, type }) => {
    const miniCart = useSelector((state) => new Set(state.cart.miniCart));
    const ownedProducts = useSelector((state) => new Set(state.cart.ownedProducts));
    const user = useSelector((state) => state.user.data);
    const [price, setPrice] = useState('');

    const dispatch = useDispatch();

    const isSubscribed = user?.subscribed ?? false;

    useEffect(() => {
        if (type) {
            if (type.includes('Notes')) {
                setPrice(20);
            }
            if (type.includes('Worksheets')) {
                setPrice(25);
            }
            if (type.includes('Cheat Sheets')) {
                setPrice(15);
            }
        }
    }, [type]);
    const renderCoverImage = (module) => {
        let url = '';
        switch (module) {
            case 'a-level':
                url = '/a-level.png';
                break;
            case 'ks3-maths-years-7-8-and-9':
                url = '/years7-9.png';
                break;
            case '11-plus':
                url = '/11-plus.png';
                break;
            case 'ap-calculus':
                url = '/ap-calculus.png';
                break;
            case 'ap-statistics':
                url = '/ap-statistics.png';
                break;
            case 'gcse-igcse-o-level':
                url = '/gcse.png';
                break;
            case 'ib':
                url = '/ib.png';
                break;
            case 'university':
                url = '/university.png';
                break;
        }
        return <img src={url} />;
    };

    function mainButtonClick(id) {
        dispatch(addToCart(id, user));
    }

    const renderCoverPage = (document) => {
        let documentState = 'Buy';

        if (miniCart.has(document.id)) {
            documentState = 'in cart';
        }

        if (ownedProducts.has(document.id)) {
            documentState = 'Purchased';
        }

        if (document.isSubscription && isSubscribed && !ownedProducts.has(document.id))
            documentState = 'Open';

        const handleDocumentClick = () => {
            switch (documentState) {
                case 'Buy':
                    mainButtonClick(document.id);
                    break;
                case 'Purchased':
                    navigate('/profile');
                    break;
                case 'Open':
                    //navigate to open page
                    navigate(`/embeded?id=${document.id}`);
                    break;
                default:
                    navigate('/cart');
                    break;
            }
        };

        const module = document.module?.slug;
        return (
            <div key={document.id} className="document-wrapper">
                <div
                    className="document-image text-center"
                    onClick={() =>
                    (window.location.href =
                        document.isSubscription && isSubscribed
                            ? document?.videoGuide?.url
                                ? `/embeded?id=${document.id}&videoGuide=${encodeURI(document?.videoGuide?.url)}`
                                : `/embeded?id=${document.id}`
                            : `/modules/${module}/${document.documentCategory
                                .map((c) => c.slug)
                                .join('/')}/${encodeURIComponent(slugify(document.name))}`)
                    }
                >
                    {renderCoverImage(module)}

                    <div className="document-name category-option-link button-text">
                        {document.displayName || document.name}
                    </div>
                </div>
                <span className="category-option-info srow y-centered action-bar x-space-between">
                    {document.freeSample && (
                        <>
                            <span className="badge free-sample">FREE SAMPLE</span>PDF
                        </>
                    )}
                    {!document.freeSample && !document.freeDocument && document.hasPassword && (
                        <>
                            <>
                                <span
                                    className={`badge premium ${document.isSubscription && isSubscribed ? 'strike-through' : ''
                                        }`}
                                >
                                    £{document.price}
                                </span>
                                {documentState === 'Open' ? (
                                    <img
                                        src={UnlockSubIcon}
                                        className="lock-icon"
                                        alt="Unlock sub icon"
                                        onClick={handleDocumentClick}
                                    />
                                ) : (
                                    <>
                                        <button
                                            className={`button-b badge free-sample ${document.isSubscription && isSubscribed ? 'green-button' : ''
                                                }`}
                                            onClick={handleDocumentClick}
                                        >
                                            {documentState}
                                        </button>
                                        {documentState === 'Purchased' ? (
                                            <img
                                                src={UnlockIcon}
                                                className="lock-icon"
                                                alt="Unlock sub icon"
                                                onClick={handleDocumentClick}
                                            />
                                        ) : document?.isSubscription ? (
                                            <img
                                                src={LockSubIcon}
                                                className="lock-icon"
                                                alt="Unlock sub icon"
                                                onClick={() => navigate('/subscriptions')}
                                            />
                                        ) : (
                                            <img
                                                src={LockIcon}
                                                className="lock-icon"
                                                alt="Unlock sub icon"
                                                onClick={handleDocumentClick}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                            <>
                                {document.previewVideo && (
                                    <div className="flex flex-center">
                                        <img src={YoutubeLogo} style={{ width: '2rem', marginRight: '0.5rem' }} />
                                        <a href={document.previewVideo} target="_blank" style={{ fontSize: '1rem' }}>
                                            Preview Video
                                        </a>
                                    </div>
                                )}
                            </>
                        </>
                    )}
                </span>
            </div>
        );
    };

    const slugify = (text) =>
        text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, ''); // Trim - from end of text

    const renderNoData = () => (
        <div className="no-data">
            <img src={NoDataImage} className="no-data-image" alt="Category optiom" />
            <p className="no-data-text">
                {`No documents here yet but we're working really hard to provide them soon!
        
        You can sign up to our newsletter in the meantime to get informed about new releases.`}
            </p>
        </div>
    );

    const renderLink = (document) => {
        const fileUrl = document.fileUrl.includes('https://mymathscloud.ams3.digitaloceanspaces.com/')
            ? unescape(document.fileUrl.replace('https://mymathscloud.ams3.digitaloceanspaces.com/', ''))
            : document.fileUrl;

        const handleDocumentClick = () => {
            if (isBrowser && document?.hasPassword) localStorage.setItem('open-modal-on-load', true);
            if (document?.isSubscription) {
                if (isSubscribed) window.location.href = `/embeded?id=${document.id}`;
                else alert('You must be subscribed to see this document.');
            } else {
                window.location.href = `${process.env.GATSBY_BACKEND_URL}/api/download/modules/${fileUrl}?id=${document.id}`;
            }
        };

        const href = document.externalUrl ? document.externalUrl :
            document?.isSubscription && isSubscribed
                ? `/embeded?id=${document.id}`
                : document.isSubscription
                    ? ''
                    : `${process.env.GATSBY_BACKEND_URL}/api/download/modules/${fileUrl}?id=${document.id}`;

        const typeOfDoc = fileUrl.endsWith('.docx')
            ? 'word'
            : fileUrl.endsWith('.pptx')
                ? 'pptx'
                : 'pdf';
        const documentMap = {
            word: {
                icon: WordDocumentIcon,
                label: 'Word',
            },
            pptx: {
                icon: PowerPointIcon,
                label: 'PowerPoint',
            },
            pdf: {
                icon: DocumentIcon,
                label: 'PDF',
            },
        };

        return (
            <li className="category-option" key={document.id}>
                <img
                    src={documentMap[typeOfDoc].icon}
                    className="category-option-image"
                    alt="Category option"
                />
                <div className="category-option-text">
                    <a
                        href={href}
                        onClick={handleDocumentClick}
                        className="category-option-link button-text mb-0 cursor-pointer"
                    >
                        {document.displayName || document.name}
                    </a>
                    <span className="category-option-info lh-1.5">
                        {document.freeSample && <span className="badge free-sample">FREE SAMPLE</span>}
                        {!document.freeSample && !document.freeDocument && document.hasPassword && (
                            <span className="badge premium">
                                {document.price || formatCurrency.format(price)}
                            </span>
                        )}
                        {documentMap[typeOfDoc].label}
                        {document.videoSolution && (
                            <span className="ml-0.5">
                                <a href={document.videoSolution} target="_blank" style={{ fontSize: '1rem' }}>
                                    Video solution
                                </a>
                                <img src={YoutubeLogo} style={{ width: '2rem', marginLeft: '0.5rem' }} />
                            </span>
                        )}
                    </span>
                </div>
                {document.isSubscription && isSubscribed ? (
                    <img src={UnlockSubIcon} className="lock-icon" alt="Unlock" />
                ) : document.isSubscription ? (
                    <img
                        src={LockSubIcon}
                        className="lock-icon"
                        alt="Locked with sub content"
                        onClick={() => navigate('/subscriptions')}
                    />
                ) : document.hasPassword ? (
                    <img src={LockIcon} className="lock-icon" alt="Locked" />
                ) : null}
            </li>
        );
    };

    const renderDoc = (document) => {
        const isFree =
            document.freeDocument ||
            document.freeSample ||
            (document.hasPassword && document.freeDocument);
        return isFree ? renderLink(document) : renderCoverPage(document);
    };

    const freeDocs = documents.filter((doc) => !doc.hasPassword || doc.freeDocument);
    const paidDocs = documents.filter((doc) => !doc.freeDocument && doc.hasPassword);

    return (
        <div>
            {showNoData && !documents.length && renderNoData()}
            {documents && documents.length > 0 && (
                <ul className="category-options">{freeDocs.map(renderDoc)}</ul>
            )}
            {paidDocs && <div className="documents">{paidDocs.map(renderDoc)}</div>}
        </div>
    );
};

export default DocumentsList;
